<template>
  <div class="carousel-wrapper">
    <Carousel 
    :items-to-show="1"
    :autoplay="8000"
    wrapAround
    class="carousel"
    >
        <Slide v-for="(step, index) in steps" :key="step.id" :id="step.id">
          <ProjectStepCard :step="step" :backgroundDirection="index % 2 === 0 ? 'left' : 'right'" />
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ProjectStepCard from "@/components/Steps/Card";


export default {
  name: "ContentCarousel",
  components: {
    ProjectStepCard,
    Carousel,
    Slide,
    Navigation,    
  },
  props: ["steps"],
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/variables";
@import "~@/assets/scss/components/_carousel.scss";

.carousel {
  width: 100%;
  margin:auto;
}

.carousel__prev, .carousel__next{
  color: white;
}
</style>