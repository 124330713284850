import store from "@/store";
import ActivityLogService from "@/services/ActivityLogService";

const getDefaultState = () => {
  return {
    userEditLogs: []
  };
};

const state = getDefaultState();

const getters = {
  getUserEditLogs: state => state.userEditLogs
};

const actions = {
    async fetchUserEditLogs({ commit }, params = {}) {
    const api = new ActivityLogService();
    
    try {
        let response = await api.fetchUserEditLogs(params);
        
        let userEditLogs = [];

        if (response.data && response.data.entries) {
            userEditLogs = response.data.entries;
        }
        console.log('userEditLogs: ' + JSON.stringify(userEditLogs));
        commit("setUserEditLogs", userEditLogs);
        return userEditLogs;
            
    } catch(err) {
        throw err;
    }

  },
//   async addUserEditLogs({ commit }, payload) {
//     const api = new ActivityLogService();
//     try {
//       let itemResponse = await api.addUserEditLogs(payload);
//       if (itemResponse.data) {
//         commit("addUserEditLogs", itemResponse.data);
//       }
//     } catch (err) {
//       throw err;
//     }
//   },
//   async saveUserEditLogs({ commit }, payload) {
//     const api = new ActivityLogService();
//     try {
//       let itemResponse = await api.saveUserEditLogs(payload.id, payload);
//       if (itemResponse.data) {
//         commit("updateUserEditLogs", itemResponse.data);
//       }
//     } catch (err) {
//       throw err;
//     }
//   },
//   async deleteUserEditLogs({ commit }, deviceId) {
//     const api = new ActivityLogService();
//     try {
//       let itemResponse = await api.deleteUserEditLogs(deviceId);
//       if (itemResponse.data) {
//         commit("deleteFromUserEditLogs", deviceId);
//       }
//     } catch (err) {
//       throw err;
//     }
//   }
};


const mutations = {
  setUserEditLogs: (state, data) => {
        state.userEditLogs = data;
  },
//   addUserEditLogs: (state, data) => {
//     state.userEditLogs.push(data);
//   },
//   updateUserEditLogs: (state, data) => {
//     console.log("updateUserEditLogs: ", data);
//     let exists = false;
//     state.userEditLogs = state.userEditLogs.map(d => {
//       if (d && data && d.id === data.id) {
//         d = data;
//         exists = true;
//       }
//       return d;
//     });

//     if (!exists)
//       state.userEditLogs.push(data);
//   },
//   deleteFromUserEditLogs: (state, id) => {
//     state.userEditLogs = state.userEditLogs.filter(d => id && d.id !== id);
//   },
  resetUserEditLogs: (state, data) => {
    state.userEditLogs = [];
  } 
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations
};
