import store from "@/store";
/**
 * Used for mapping kentico campaigns into same format as liferay campaigns
 */
export default class CampaignModel {
    constructor() {
      this.id = null;
      this.campaignId = null;
      this.codename = null;
      this._type = 'campaign';
      this.start = new Date().toString();
      this.end = new Date().toString();
      this.imageURL = "";
      this.images = "";
      this.content = "";
      this.partnerGroupId = "";
      this.partnerExternalId = "";
      this.partnerName = "";
      this.user_id = null;
      this.buildingTaxonomies = [];
      this.buildingTaxonomyValues = [];
      this.source = null;
      this.link = null;
      this.linktext = null;
      this.sticky = false;
      this.stickyExpirationDate = null;
      this.attachments = [];
      this.categories = [];
      this.video = ""
    }

    /**
     * Build from Kentico model
     * @param {*} article 
     */
    static buildFromKenticoModel(article) {
        let newArticle = new CampaignModel();

        if(!article.elements || !article.system)
            return newArticle;

        newArticle.id = article.system.id;
        newArticle.campaignId = article.system.id;  //fallback for liferay model
        newArticle.codename = article.system.codename;
        if(article.elements.start_time)
            newArticle.start = article.elements.start_time.value;
        if(article.elements.end_time)
            newArticle.end = article.elements.end_time.value;
        if(article.elements.content)
            newArticle.content = article.elements.content.value;
        if(article.elements.title)
            newArticle.title = article.elements.title.value;
        if(article.elements.images && article.elements.images.value && article.elements.images.value.length) {
            newArticle.imageURL = article.elements.images.value[0].url;
            newArticle.images = article.elements.images;
        }
        if(article.elements.attachments && article.elements.attachments.value && article.elements.attachments.value.length) {
            newArticle.attachments = article.elements.attachments.value;
        }
        if(article.elements.partner_external_id)
            newArticle.partnerGroupId = article.elements.partner_external_id.value;
        if(article.elements.partner_external_id)
            newArticle.partnerExternalId = article.elements.partner_external_id.value;
        if(article.elements.partner_name)
            newArticle.partnerName = article.elements.partner_name.value;

        if(article.elements.cta_button_text)
            newArticle.linktext = article.elements.cta_button_text.value;
        if(article.elements.cta_button_url)
            newArticle.link = article.elements.cta_button_url.value;

        if(article.elements.buildingtaxonomy) {
            newArticle.buildingTaxonomies = article.elements.buildingtaxonomy.values;
            article.elements.buildingtaxonomy.value.map(t => {
                newArticle.buildingTaxonomyValues.push(t.codename);
                return t;
            });
        }

        if(article.elements.video)
            newArticle.video = article.elements.video.value;

        if(article.elements.user_id)
          newArticle.user_id = article.elements.user_id.value;
      
        if (article.elements.sticky_news && Array.isArray(article.elements.sticky_news.value) && article.elements.sticky_news.value.length == 1) {
          if (article.elements.expiration_date_of_the_sticky) {
            if(new Date(article.elements.expiration_date_of_the_sticky.value).getTime() > new Date().getTime())
            newArticle.sticky = String(article.elements.sticky_news.value).toLowerCase() == 'true' ? true : false;
            newArticle.stickyExpirationDate = new Date(article.elements.expiration_date_of_the_sticky.value);
          }
        }
      
        if(article.elements.cta_button_text)
          newArticle.linktext = article.elements.cta_button_text.value;
      
        if(article.elements.campaign_category) {
            newArticle.categories = article.elements.campaign_category.value;
        }
        
        newArticle.source = "kentico";

        return newArticle;
    }

    /**
     * Build from Kentico Content variant model
     * @param {*} article 
     */
    static fromKenticoLanguageVariantModel(article) {
        let newArticle = new CampaignModel();

        if(!article || !article.elements || !article.item)
            return new CampaignModel();

        newArticle.id = article.item.id;

        if(article.elements.buildingtaxonomy) {
            newArticle.buildingTaxonomies = article.elements.buildingtaxonomy;
            article.elements.buildingtaxonomy.map(t => {
                let tax = store.getters.getBuildingTaxonomyCodenameById(t.id);
                if(tax && tax.length)
                    tax.map(_t => newArticle.buildingTaxonomyValues.push(_t.codename));
                return t;
            });
        }

        if(article.elements.partner_external_id)
            newArticle.partnerExternalId = article.elements.partner_external_id;
        if(article.elements.partner_name)
            newArticle.partnerName = article.elements.partner_name;

        if(article.elements.user_id)
            newArticle.user_id = article.elements.user_id;
        
        return newArticle;
    }

    static fromLiferayModel(article) {
        let newArticle = new CampaignModel(); 
        Object.assign(newArticle, article);
        return newArticle;
    }
}