export default (user, { can, cannot, rules }) => {
  if (
    user.hasRole("yitMarketing") ||
    user.hasRole("yitBusinessAdmin") ||
    user.hasRole("yitProject") ||
    user.hasRole("habManager")
  ) {
    can("manage", "users");
    can("manage", "user");
  }

  if (
    user.hasRole("yitBusinessAdmin")
  ) {
    can("ban", "user")
  }

  if (user.hasRole("yitProjectInternal")) {
    cannot(["create", "update", "delete"], "user");
  }

  if (Array.isArray(user.hcs)) {
    user.hcs.map((hc) => {
      if (
        user.hasRole("propertyManager") ||
        user.hasRole("boardMember") ||
        user.hasRole("chairman") ||
        user.hasRole("userManager") ||
        user.hasRole("ceeMaintenanceManager")
      ) {
        can("create", "user");
      }

      if (
        hc.roles.includes("propertyManager") ||
        hc.roles.includes("boardMember") ||
        hc.roles.includes("chairman") ||
        hc.roles.includes("userManager") ||
        hc.roles.includes("ceeMaintenanceManager")
      ) {
        can("manage", "users", {
          projectId: hc.hcExt,
        });
      }

      /**
       * We'll uncomment the roles, once got the confirmation from Jussi
       */
      // if (
      //   hc.roles.includes("propertyManager") ||
      //   hc.roles.includes("chairman") ||
      //   hc.roles.includes("boardMember") ||
      //   hc.roles.includes("userManager") ||
      //   hc.roles.includes("ceeMaintenanceManager")
      // ) {
      //   can(["read", "update"], "user", {
      //     hcs: {
      //       $elemMatch: { hcExt: hc.hcExt },
      //     },
      //   });
      // }

      if (
        hc.roles.includes("yitProject") ||
        hc.roles.includes("yitProjectInternal") ||
        hc.roles.includes("propertyManager") ||
        hc.roles.includes("chairman") ||
        hc.roles.includes("boardMember") ||
        hc.roles.includes("userManager") ||
        hc.roles.includes("ceeMaintenanceManager")
      ) {
        can("ban", "user", {
          projectId: hc.hcExt
        })
      }

      if (Array.isArray(hc.apts)) {
        hc.apts.map((apt) => {
          if (
            apt.roles &&
            (apt.roles.includes("tenant") ||
              apt.roles.includes("owner") ||
              apt.roles.includes("rentalManager") ||
              apt.roles.includes("habManager"))
          ) {
            can("manage", "apartment-users");
          }
        });
      }
    });
  }
};
