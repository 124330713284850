export default (user, { can, cannot, rules }) => {
  can(["read", "reply"], "servicerequest", {
    public: true,
    hcExtId: { $in: user.hcExtIds }
  });
  can(["read", "update", "reply"], "servicerequest", {
    senderUserUuid: user.id
  });
  can(["manage"], "servicerequestresponse", {
    userUuid: user.id
  });
  can(["create"], "servicerequest", {
    hcExtId: { $in: user.hcExtIds }
  });

  can(["read", "reply"], "servicerequest", {
    public: true,
    hcExtId: { $in: user.hcExtIds }
  });

  can(["read", "reply"], "servicerequest", {
    senderUserUuid: user.id
  });

  can(["addAttachment"], "servicerequest", {
    senderUserUuid: user.id
  });

  //navigation link in admin panel
  if (user.hasRole("yitUser") || user.hasRole("propertyManager") || user.hasRole("maintenanceManager") || user.hasRole("ceeMaintenanceManager") || user.hasRole("boardMember") || user.hasRole("chairman") || user.hasRole("rentalManager") || user.hasRole('habManager')) {
    can("manage", "servicerequests");
  }

  if (user.hasRole('yitBusinessAdmin') || user.hasRole('yitMarketing')) {
    can(["manage"], "servicerequest", {
      hcExtId: { $in: user.hcExtIds }
    });
  }

  if (user.hasRole('yitProject') || user.hasRole('yitProjectInternal')) {
    can(["manage"], "servicerequest", {
      hcExtId: { $in: user.hcExtIds },
      partnerCompanyId: ""
    });
  }

  if (user.hasRole('partner')) {
    can(["read", "update", "reply"], "servicerequest", {
      partnerCompanyId: { $in: user.hcExtIds }
    });
  }
  
  if (user.hasRole("yitUser")) {
    can(["read"], "servicerequests/report");
  }

  if (user.hasRole('propertyManager') || user.hasRole('maintenanceManager')) {
    
    can(["read", "update", "reply"], "servicerequest", {
      hcExtId: { $in: user.hcExtIds },
      partnerCompanyId: { $in: user.hcExtIds }
    });

    can(["read", "update", "reply"], "servicerequest", {
      recipients: { $elemMatch: { userUuid: user.id } }
    });

    can(["read", "update", "reply"], "servicerequest", {
      hcExtId: { $in: user.hcExtIds },
      public: true
    });

  }

  if (user.hasRole('chairman') || user.hasRole('boardMember')) {
    if (Array.isArray(user.hcs)) {
      user.hcs.map(hc => {
        if (Array.isArray(hc.roles) && (hc.roles.includes('chairman') || hc.roles.includes('boardMember'))) {
          can(["read", "update", "reply"], "servicerequest", {
            recipients: { $elemMatch: { userUuid: user.id } }
          });
          can(["read", "update", "reply"], "servicerequest", {
            hcExtId: hc.hcExt,
            public: true
          });
          can(["read", "update", "reply"], "servicerequest", {
            hcExtId: hc.hcExt,
            typeId: "housing.association.board"
          });
        }
      });
    }
  }

  if (user.hasRole('rentalManager') || user.hasRole('habManager')) {
    can(["read", "update", "reply"], "servicerequest", {
      recipients: { $elemMatch: { userUuid: user.id } }
    });
  }

  /**
   * Access with service request access token
   */
  if (user.hasRole('maintenanceManager')) {
    can(["read", "update"], "servicerequest", {
      _id: user.serviceRequestId
      //recipients: { $elemMatch: { _id: user._id } }
    });
  }
  
}