import store from "@/store";

import { newsFeature } from "@/features/news";
import { calendarFeature } from "@/features/calendar";
import { navigationLinkFeature } from "@/features/navigation-link";
import { serviceLoungeFeature } from "@/features/service-lounge";
import { materialSelectionFeature } from "@/features/material-selection";
import { serviceRequestsFeature } from "@/features/service-requests";
import { projectCameraFeature } from "@/features/project-camera";
import { one4allFeature, electroluxFeature } from "@/features/common-spaces";
import { warrantyFeature } from "@/features/warranty";
import { areaDiscussionFeature, neighboursFeature, publicDiscussionFeature } from "@/features/community";
import { consumptionFeature } from "@/features/consumption";
import { atpFeature } from "@/features/atp";
import { movementSensorsIotFeature } from "@/features/iot";
import { yitNewsFeature } from "@/features/yit-news";
import {documentsFeature, documentsv2Feature} from "@/features/documents";
import { faqFeature } from "@/features/faq";
import { adminHelpFeature } from "@/features/admin-help";
import { emailLettersFeature } from "@/features/email-letters";
import { habFeature } from "@/features/hab";

/**
 * Application features
 */

const ApplicationFeatures = [
  newsFeature,
  calendarFeature,
  navigationLinkFeature,
  serviceLoungeFeature,
  materialSelectionFeature,
  serviceRequestsFeature,
  projectCameraFeature,
  one4allFeature,
  electroluxFeature,
  warrantyFeature,
  areaDiscussionFeature,
  neighboursFeature,
  publicDiscussionFeature,
  consumptionFeature,
  atpFeature,
  movementSensorsIotFeature,
  yitNewsFeature,
  documentsFeature,
  documentsv2Feature,
  faqFeature,
  adminHelpFeature,
  emailLettersFeature,
  habFeature,
  /**
   * Display partner company cards in contacts page (deprecating soon..ish?)
   */
  {
    id: "partner-company-detail-cards",
    name: "feature.partner-company-detail-cards.name",
    globalSettings: {},
    schema: {}
  }
];

/**
 * Return all data of asked feature
 * @param {String} feature
 */
const getFeature = feature => {
  //return feature if it's active
  let features = store.getters.getUserFeatures;
  if (features[feature]) return features[feature];

  //return feature forced to be active
  let forcedFeature = ApplicationFeatures.find(f => f.forceActive === true && f.id === feature);
  if (forcedFeature) return forcedFeature;
};

const getFeatureSchema = featureId => {
  let result = ApplicationFeatures.filter(feature => feature.id == featureId);
  if (result && result.length) {
    return result[0].schema;
  }

  return null;
};

const getFeatureInformation = featureId => {
  let result = ApplicationFeatures.filter(feature => feature.id == featureId);
  if (result && result.length) {
    return result[0];
  }

  return null;
};

/**
 * Return all data of asked feature
 * @param {String} feature
 */
const initializeFeatures = () => {
  if (store.getters.getUserFeatures) {
    ApplicationFeatures.map(feature => {
      if (feature) {
        //Unregister callback
        if (typeof feature.unregister === "function") feature.unregister(feature);

        //Register callback
        if (typeof feature.register === "function") feature.register(feature);

        //Initialize callback (feature is set on)
        let featureDetails = getFeature(feature.id);
        if (featureDetails) {
          console.log(
            "Initializing feature: ",
            feature.id,
            featureDetails
          );
          if (typeof feature.initialize === "function")
            feature.initialize(feature, featureDetails.settings);
        }
      }
    });
  }
};

export {
  ApplicationFeatures,
  getFeature,
  getFeatureInformation,
  initializeFeatures,
  getFeatureSchema
};
