import LiferayClient from "./LiferayClient";
import { AzureFunctionClient, includeJwt } from "./AzureFunctionClient";
import {ApimPublicClient} from "./ApimPublicClient";
import BaseService from "./BaseService";
import store from "@/store";
import dayjs from "dayjs";
import settings from "@/globals";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export default class UserService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "/delegate/yithome-api/userdetails";
    this.hcApi = "/delegate/yithome-api/hcinformation";
  }
  /**
   * Logout from Liferay
   */
  logout() {
    store.dispatch('clearTokens');
    return Promise.resolve('ok');
  }
  /**
   * Check session status
   */
  getUserSessionStatus() {
    return LiferayClient.get(`${this.baseUrl}/session`);
  }
  /**
   * Get User Details
   */
  getUserDetails() {
    return LiferayClient.get(`${this.baseUrl}/status`);
  }

  /**
   * Deactivate User
   */
  deactivateUser(reason) {
    return LiferayClient.post(`${this.baseUrl}/deactivate`, reason);
  }

  /**
   * Remove User
   */
  removeUser(reason) {
    return LiferayClient.post(`${this.baseUrl}/remove`, reason);
  }

  /**
   * Register User
   */
  sendRegistration(form) {
    let postData = {
      locale: form.locale,
      birthday: form.birthday,
      phoneNumber: form.phoneNumber,
      male: form.male,
      publicProfile: form.publicProfile
    };

    console.log("Creating new registration: ", postData);

    return LiferayClient.post(`${this.baseUrl}/register`, postData);
  }

  /**
   * Set notification settings
   */
  setNotifications(form, params = {}) {

    let settingData = {
      "settings": {
        "PUBLIC_DISCUSSIONS": {
          "active": form.settings.discussions
        },
        "PUBLIC_DISCUSSIONS_REPLY": {
          "active": form.settings.discussions
        },
        "NEIGHBOUR_MESSAGES": {
          "active": form.settings.neighbours
        },
        "NEIGHBOUR_MESSAGES_REPLY": {
          "active": form.settings.neighbours
        },
        "NEWS_COMMENT": {
          "active": form.settings.news
        },
        "ALL_NEWS_POSTS": {
          "active": form.settings.news
        },
        "CALENDAR_EVENTS": {
          "active": form.settings.calendars
        },
        "ADVERTISEMENT": {
          "active": form.settings.partners
        },
        "DOCUMENT_UPLOAD": {
          "active": form.settings.documents
        }
      }
    }

    let queryParams = { projectId: params && params.projectId ? params.projectId : store.getters.getSelectedHousingCompany.hcExt };

    if (params && params.targetUserUuid)
      queryParams.targetUserUuid = params.targetUserUuid;
      
    let queryPath = this._buildUrl('/notificationSettings', queryParams);

    if (typeof form.emailNotificationsDisabled != 'undefined')
      settingData.emailNotificationsDisabled = form.emailNotificationsDisabled;
    if (typeof form.pushNotificationsDisabled != 'undefined')
      settingData.pushNotificationsDisabled = form.pushNotificationsDisabled;

    return AzureFunctionClient.post(queryPath, settingData);
  }

  getUsers(params = {}) {
    console.log("FETCH USERS: ", params);
    let query = this._buildUrl(`/user`, params);
    console.log("USERS QUERY", query);
    const projectId = params.projectId || "";
    
    //let roles = ["roleOwner","roleTenant","roleFamilyMember","rolePropertyManager","roleMaintenanceManager","roleBoardMember","roleChairman"];
    let roles = [
      "roleOwner", "roleTenant", "roleFamilyMember", "rolePropertyManager", "roleMaintenanceManager", "roleBoardMember", "roleCeeBoardMember","roleChairman",
      "owner", "tenant", "familyMember", "propertyManager", "maintenanceManager", "boardMember", "ceeBoardMember", "chairman", "roleUserManager", "userManager", "consumptionManager",
      "rentalManager", "habManager", "ceeMaintenanceManager", "contentPublisher"
    ];
    if(params.roles) {
      if(Array.isArray(params.roles))
        roles = params.roles;
      else
        roles = [params.roles];
    }

    return AzureFunctionClient.get(`/user/?custom=true&projectId=${projectId}&roles=${roles.join()}`);
  }

  getAptUsers(filter) {
    return AzureFunctionClient.get(`/getApartmentUsers?filter=${filter}`);
  }

  getUser(params = {}) {
    let query = this._buildUrl(`/user`, params);
    return AzureFunctionClient.get(`${query}`);
  }

  setUser(form) {
    let body =  {
      projectId: form.projectId,
      apartmentId: form.apartmentId,
      email: form.email,
      givenName: form.firstName,
      surname: form.lastName,
      preferredLanguage: form.locale,
      role: form.role,
      currentAptUsers: form.currentAptUsers,
      ownershipChangeStartDate: form.ownershipChangeStartDate ? dayjs(form.ownershipChangeStartDate).format('YYYY-MM-DD') : null,
    }

    if (form.externalId)
      body.externalId = form.externalId;

    //convert dates to simple date strings
    if (Array.isArray(body.currentAptUsers) && body.currentAptUsers.length) {
      body.currentAptUsers = body.currentAptUsers.map(u => {
        if (u.scheduledEndDate) {
          u.scheduledEndDate = dayjs(u.scheduledEndDate).format('YYYY-MM-DD');
        }

        return u;
      })
    }

    return AzureFunctionClient.post(`/userGroups?operation=update`, body);
  }

  updateUserRoles(form, operation = 'update') {
    const body =  {
      projectId: form.projectId,
      email: form.email,
      givenName: form.givenName,
      surname: form.surname,
      role: form.role
    }
    return AzureFunctionClient.post(this._buildUrl(`/userGroups`, {operation}), body);
  }

  updateUser(body, operation = 'update') {
    return AzureFunctionClient.post(this._buildUrl(`/userGroups`, {operation}), body);
  }

  getUserSignatures() {
    return AzureFunctionClient.get(`/user/signatures`);
  }

  createUserSignature(signature) {
    return AzureFunctionClient.post(`/user/signatures`, signature);
  }

  saveUserSignature(signature) {
    return AzureFunctionClient.post(`/user/signatures`, signature);
  }

  deleteUserSignature(signature) {
    return AzureFunctionClient.delete(`/user/signatures`, { data: signature });
  }


  /**
   * Get Housing companies in current area
   */
  async getHousingCompaniesInArea() {
    let hc = store.getters.getSelectedHousingCompany;
    if(!hc)
      throw "Housing company could not be retrieved";

    let area = hc.marketingName;

    if(!area)
      return;

    area = area.replace(/\s/g, "+");
    
    if (this.routeViaLiferay()) {
      return LiferayClient.get(`${this.hcApi}/getHousingCompaniesByArea/${area}`);
    } else {
      let query = this._buildUrl("/getListOfHousingCompaniesByArea", {area});
      return AzureFunctionClient.get(query);
    }
  }

  /**
   * Get spesific details of housing company
   */
  getHousingCompanyDetails(project = null) {

    if(!project)
      project = store.getters.getSelectedHousingCompany;

    if (this.routeViaLiferay()) {
      let projectId = project.id;
      return LiferayClient.get(`${this.baseUrl}/hc/${projectId}`);
    } else {
      let projectId = project.hcExt;
      if(projectId)
        return AzureFunctionClient.get(`/getHousingCompanyDetailsForUser?projectId=${projectId}`);
      else
        return Promise.resolve();
    }
  }

  //Get auth token from b2c
  getAccessToken(code = null, refresh = false, headers = {}) {
    let query;
    if(code) {
      query = this._buildUrl("/getAccessToken", {code});
    } else {
      query = "/getAccessToken";
    }
    return ApimPublicClient.post('/getAccessToken', {
      code: code ? code : null,
      refresh: refresh ? true : false 
    }, headers);
  }

  fetchGDPRReport() {
    return AzureFunctionClient.get(`/generateUserInformationReport`);
  }

  //Get auth token from b2c
  changeUserEmail(userUuid, newEmail) {
    return AzureFunctionClient.post(`changeUserEmail/${userUuid}`, {newEmail});
  }

  //Get auth token from b2c
  changeUserEmailChange(userUuid, confirmCode) {
    return AzureFunctionClient.post(`changeUserEmail/${userUuid}/${confirmCode}`);
  }

  changeUserPassword(userUuid, newPassword) {
    return AzureFunctionClient.post(`changeUserPassword/${userUuid}`, {newPassword});
  }

  updateScheduledRoleChange(payload, operation = 'remove') {
    let postData = {
      operation
    };

    if (payload.projectId)
      postData.projectId = payload.projectId;
    if (payload.apartmentId)
      postData.apartmentId = payload.apartmentId;
    if (payload.userUuid)
      postData.userUuid = payload.userUuid;
    if (payload.role)
      postData.role = payload.role;
    
    return AzureFunctionClient.post(`/updateScheduledRoleChange`, postData);
  }

  banUser(userUuid, payload = {}) {
    return AzureFunctionClient.post(`/user/ban/${userUuid}`, payload)
  }

  removeBan(userUuid, payload = {}) {
    return AzureFunctionClient.delete(`/user/ban/${userUuid}`, { data: payload })
  }

  markUserDialogRead(payload) {
    return AzureFunctionClient.post(`/dialog`, payload);
  }

  /**
   * Mark kentico item done/un-done
   * @param {String} uuid 
   * @param {Boolean} status 
   */
  async markTaskDone(uuid, status = true) {
    return AzureFunctionClient.post(`/task/${uuid}/status/${status.toString()}`, {})
  }
  
  /**
   * 
   * @param {String} projectId 
   * @param {Array} links - Array of link objects
   * @returns
   */
  async updateUserQuickLinks(projectId, links = []) {
    if (!projectId)
      throw new Error('Project ID must be specified in order to update quick links')
    
    return AzureFunctionClient.put('/user/quick-links', {
      projectId,
      links
    })
  }
}
