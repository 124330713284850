import BaseService from "./BaseService";
import { AzureFunctionClient } from "./AzureFunctionClient";


export default class ActivityLogService extends BaseService {
  constructor() {
    super();
  }
  
  /**
   * 
   * @param {*} aptExtId 
   * @param {*} params 
   */
  fetchUserEditLogs(payload = {}) {
    let query = this._buildUrl(`/getActivityLogs`, payload);
    return AzureFunctionClient.get(query);
  }

//   async addUserEditLogs(payload) {
//     return AzureFunctionClient.post('/device/', payload);
//   }

//   async saveUserEditLogs(userId, payload) {
//     return AzureFunctionClient.put(`/device/${userId}`, payload);
//   }

//   async deleteUserEditLogs(userId) {
//     return AzureFunctionClient.delete(`/device/${userId}`);
//   }
}