const habFeature = {
  id: "hab",
  name: "feature.hab.name",
  iconClass: "fal fa-building",
  globalSettings: {},
  schema: {
      
  }
};

export { habFeature };