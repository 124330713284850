<template>
  <Modal @close="displayWelcomeModal=false" colorMode="dark" :displayCloseButton="false" class="welcome-to-yit-plus-modal" v-if="displayWelcomeModal">
    <h2>{{$t('welcome-dialog.title')}}</h2>
    <p class="lead">{{$t('welcome-dialog.text')}}</p>
    <div class="position--relative welcome-to-yit-plus-modal__carousel-wrapper" v-if="getSteps.length">
      <ContentCarousel :steps="getSteps" />
    </div>
    <template v-slot:footer>
      <div class="button-group text-center">
        <Button type="button" mode="link" @click="onCloseDialog">{{$t('welcome-dialog.close')}}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import ProjectStepCard from "./Card";
import {ContentCarousel} from "@/components/common/Carousel";
import Modal from "@/components/common/Modal";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "WelcomeDialog",
  components: {
    Modal,
    ProjectStepCard,
    ContentCarousel
  },
  data() {
    return {
      id: 'welcome-to-plus-dialog',
      displayWelcomeModal: true,
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters(['getSteps', 'getSelectedHousingCompany'])
  },
  methods: {
    ...mapActions(['markUserDialogRead']),
    onCloseDialog() {
      this.markUserDialogRead( {id: this.id, projectId: this.getSelectedHousingCompany.hcExt} );
      this.displayWelcomeModal=false;
    }
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/abstracts/variables";
.welcome-to-yit-plus-modal {

  &__carousel-wrapper {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: $blueLightest;
  }

  @media screen and (max-width: $breakpoint-md) {
    .modal-container {
      max-width: 100vw;
    }
  }
  .modal__body {
    overflow-x: hidden;
  }

  .step-card {
    min-height: 17rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    h1,h2,h3,h4,h5,h6 {
      font-size: 1.6rem;
    }
  }

  .content-carousel__arrows {
    .right, .left {
      color: #fff;
    }
    .right {
      right: 0rem;
    }
    .left {
      left: 0rem;
    }
  }
}
</style>