<template>
  <div style="display: inline;">
    <span
      class="conversation__apartment apartment-label"
      v-if="getApartment"
      >{{ getApartment }} 
    </span>
    <span class="quick-link__arrow" v-if="(roles.includes('propertyManager') || roles.includes('maintenanceManager')) && (partnerCompanies !== '')">
        <i class="fal" :class="{ 'fa-chevron-up': isRotate, 'fa-chevron-down': !isRotate }" @click="toggleArrowBtn()"></i>
    </span>
    <div class="housingContent" v-if="housingContent">{{ partnerCompanies }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name:"CommunityUserTagLabel",
  data() {   
    return {     
      housingContent: false,
      isRotate: false,
      hCName: ""
    }  
  },
  methods: {
    toggleArrowBtn: function() {
      this.isRotate = !this.isRotate;
      this.housingContent = !this.housingContent;
    }
  },
  props: {
    roles: {type: Array},
    apartment: {type: String},
    conversationType: {type: String, default: 'public'}, //area/public
    projectId: {type: String},
    partnerCompanies: {type: String},
  },
  computed: {
    ...mapGetters(['getAreaNameByExtId']),
    getApartment() {
      if(Array.isArray(this.roles) && this.roles.length) {
        if(this.roles.includes('admin') || this.roles.includes('yitAdmin') || this.roles.includes('yitMarketing') || this.roles.includes('yitBusinessAdmin') || this.roles.includes('yitProject') || this.roles.includes('yitProjectInternal'))
          return "YIT";
        else if(this.roles.includes('propertyManager'))
          if(this.conversationType != 'area')
            return this.$t('user.admin.role.rolePropertyManager');
          else
          return this.$t('user.admin.role.rolePropertyManager');
        else if(this.roles.includes('maintenanceManager') || this.roles.includes('ceeMaintenanceManager'))
          if(this.conversationType != 'area')
            return this.$t('user.admin.role.roleMaintenanceManager');
          else
          return this.$t('user.admin.role.roleMaintenanceManager');
        else if(this.roles.includes('boardMember') || this.roles.includes('chairman') || this.roles.includes('ceeBoardMember')) {
          if(this.conversationType == 'area') {
            if(this.apartment != '-')
              return this.getAreaNameByExtId(this.projectId);
            else
              return `${this.apartment} ${this.getrole}`;
          } else 
            return this.$t('community.label.administration');
        }
        else {
          return `${this.apartment} ${this.getrole}`;
        }
      } else if(this.apartment) {
        if(this.conversationType == 'area') {
          if(this.apartment != '-')
            return this.getAreaNameByExtId(this.projectId);
          else
            return `${this.apartment} ${this.getrole}`;
        } else {
          if (['community.label.administration', 'user.admin.role.rolechairman', 'user.admin.role.roleboardmember', 'user.admin.role.rolepropertymanager', 'user.admin.role.rolemaintenancemanager'].includes(this.apartment.toString().toLowerCase()))
            return this.$t(this.apartment);
          else
            return `${this.apartment} ${this.getrole}`;
        }
      } else {
        if(this.conversationType == 'area') {
          return this.getAreaNameByExtId(this.projectId);
        }
      }

      return null;
    },
    getrole() {
      if(this.conversationType == 'serviceRequest') {
        let rolesArray = [];
        if(Array.isArray(this.roles) && this.roles.length) {
          this.roles.forEach((role) =>{
            if(role == 'tenant') {
              rolesArray.push(`- ${this.$t('user.admin.role.roleTenant')}`);
            } 
            else if(role == 'owner') {
              rolesArray.push(`- ${this.$t('user.admin.role.roleOwner')}`);
            } 
            else if (role == 'familyMember') {
              rolesArray.push(`- ${this.$t('user.admin.role.roleFamilyMember')}`);
            } 
          })
          return rolesArray.join(' ');
        }
        else {
          if(this.roles == 'tenant') {
            rolesArray.push(`${this.$t('user.admin.role.roleTenant')}`);
          } 
          else if(this.roles == 'owner') {
            rolesArray.push(`${this.$t('user.admin.role.roleOwner')}`);
          } 
          else if (this.roles == 'familyMember') {
            rolesArray.push(`${this.$t('user.admin.role.roleFamilyMember')}`);
          } 
        }
      }
      else {
        return '';
      }
    }
  }
}
</script>

<style>
  .quick-link__arrow {
    padding: 0 0 0 5px;
    font-size: 10px;
    vertical-align: middle;
    cursor: pointer;
  }
  .housingContent{font-size: small};
</style>