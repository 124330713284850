import _ from "lodash";
import { i18n } from "@/plugins/i18n";
import { getURLParam } from "@/utils/StringUtils";
import { ApplicationFeatures } from "@/features.js";
import {getBrowserLocale} from "@/utils/LocalizationUtil";

const settings = {
  langs: [
    { langKey: "cs", locale: "cs_CZ", localeDisplayName: "Czech" },
    { langKey: "et", locale: "et_EE", localeDisplayName: "Eesti" },
    { langKey: "en", locale: "en_US", localeDisplayName: "In English" },
    { langKey: "fi", locale: "fi_FI", localeDisplayName: "Suomeksi" },
    { langKey: "lt", locale: "lt_LT", localeDisplayName: "Lithuanian" },
    { langKey: "lv", locale: "lv_LV", localeDisplayName: "Latvian" },
    { langKey: "pl", locale: "pl_PL", localeDisplayName: "Polish" },
    { langKey: "sk", locale: "sk_SK", localeDisplayName: "Slovak" },
    { langKey: "sv", locale: "sv_FI", localeDisplayName: "På Svenska" }
  ],
  countries: [
    { name: "Czeck", key: "CZE" },
    { name: "Estonia", key: "EST" },
    { name: "Finland", key: "FIN" },
    { name: "Latvia", key: "LVA" },
    { name: "Lithuaninan", key: "LTU" },
    { name: "Poland", key: "POL" },
    { name: "Slovakia", key: "SVK" }
  ],
  UIRoles: {
    admin: {
      name: "admin",
      translationKey: "user.admin.role.roleAdmin",
      type: 'role',
      visible: true
    },
    yitAdmin: {
      name: "yitAdmin",
      translationKey: "user.admin.role.roleYitAdmin",
      type: 'role',
      visible: true
    },
    yitBusinessAdmin: {
      name: "yitBusinessAdmin",
      translationKey: "user.admin.role.roleYitBusinessAdmin",
      type: 'role',
      visible: true
    },
    yitMarketing: {
      name: "yitMarketing",
      translationKey: "user.admin.role.roleYitMarketing",
      type: 'role',
      visible: true
    },
    yitProject: {
      name: "yitProject",
      translationKey: "user.admin.role.roleYitProject",
      type: 'role',
      visible: true
    },
    yitProjectInternal: {
      name: "yitProjectInternal",
      translationKey: "user.admin.role.roleYitProjectInternal",
      type: 'role',
      visible: true
    },
    yitUser: {
      name: "yitUser",
      translationKey: "user.admin.role.roleYitUser",
      type: 'alias',
      visible: false
    },
    contributor: {
      name: "contributor",
      translationKey: "user.admin.role.roleContributor",
      type: 'alias',
      visible: false
    },
    propertyManager: {
      name: "propertyManager",
      translationKey: "user.admin.role.rolePropertyManager",
      type: 'role',
      visible: true
    },
    maintenanceManager: {
      name: "maintenanceManager",
      translationKey: "user.admin.role.roleMaintenanceManager",
      type: 'role',
      visible: true
    },
    boardMember: {
      name: "boardMember",
      translationKey: "user.admin.role.roleBoardMember",
      type: 'role',
      visible: true
    },
    chairman: {
      name: "chairman",
      translationKey: "user.admin.role.roleChairman",
      type: 'role',
      visible: true
    },
    owner: {
      name: "owner",
      translationKey: "user.admin.role.roleOwner",
      type: 'role',
      visible: true
    },
    tenant: {
      name: "tenant",
      translationKey: "user.admin.role.roleTenant",
      type: 'role',
      visible: true
    },
    familyMember: {
      name: "familyMember",
      translationKey: "user.admin.role.roleFamilyMember",
      type: 'role',
      visible: true
    },
    partner: {
      name: "partner",
      translationKey: "user.admin.role.rolePartner",
      type: 'role',
      visible: true
    },
    userManager: {
      name: "userManager",
      translationKey: "user.admin.role.roleUserManager",
      type: 'role',
      visible: true
    },
    ceeBoardMember: {
      name: "ceeBoardMember",
      translationKey: "user.admin.role.roleCeeBoardMember",
      type: 'role',
      visible: true
    },
    ceeMaintenanceManager: {
      name: "ceeMaintenanceManager",
      translationKey: "user.admin.role.roleCeeMaintenanceManager",
      type: 'role',
      visible: true
    },
    rentalManager: {
      name: "rentalManager",
      translationKey: "user.admin.role.roleRentalManager",
      type: 'role',
      visible: true
    },
    habManager: {
      name: "habManager",
      translationKey: "user.admin.role.roleHabManager",
      type: 'role',
      visible: true
    },
    consumptionManager: {
      name: "consumptionManager",
      translationKey: "user.admin.role.roleConsumptionManager",
      type: 'role',
      visible: true
    },
    contentPublisher: {
      name: "contentPublisher",
      translationKey: "user.admin.role.roleContentPublisher",
      type: 'role',
      visible: true
    }
  },
  //used in User.js -model to loop through all "admin" roles
  projectRoleGroups: [
    'admin', 'yitAdmin', 'yitUser', 'yitBusinessAdmin', 'yitMarketing', 'yitProject', 'partner', 'propertyManager', 'maintenanceManager', 'userManager', 'ceeMaintenanceManager', 'rentalManager', 'habManager', 'consumptionManager', 'contentPublisher'
  ],
  roleMap: {
    admin: ["liferayAdmin", "roleYitAdmin", "yitAdmin", "admin"],
    yitAdmin: [
      "roleYitBusinessAdmin",
      "roleYitMarketing",
      "yitBusinessAdmin",
      "yitMarketing"
    ],
    yitUser: [
      "roleYitBusinessAdmin",
      "roleYitService",
      "roleYitMarketing",
      "roleYitProject",
      "yitBusinessAdmin",
      "yitService",
      "yitMarketing",
      "yitProject",
      "yitProjectInternal"
    ],
    yitBusinessAdmin: ["roleYitBusinessAdmin", "yitBusinessAdmin"],
    yitMarketing: ["roleYitMarketing", "yitMarketing"],
    yitProject: ["roleYitProject", "yitProject"],
    yitProjectInternal: ["roleYitProjectInternal", "yitProjectInternal"],
    contributor: ["rolePropertyManager", "roleMaintenanceManager", "roleBoardMember", "roleChairman", "propertyManager", "maintenanceManager", "boardMember", "chairman", "ceeBoardMember", "userManager", "ceeMaintenanceManager", "rentalManager", "habManager", "consumptionManager", "contentPublisher"],
    propertyManager: ["rolePropertyManager", "propertyManager"],
    maintenanceManager: ["roleMaintenanceManager", "maintenanceManager"],
    boardMember: ["roleBoardMember", "boardMember"],
    chairman: ["roleChairman", "chairman"],
    owner: ["roleOwner", "orgRoleOwner", "owner"],
    partner: ["rolePartner", "partner"],
    tenant: [ "roleTenant", "tenant"],
    userManager: ["roleUserManager", "userManager"],
    ceeBoardMember: ["roleCeeBoardMember", "ceeBoardMember"],
    ceeMaintenanceManager: ["roleCeeMaintenanceManager", "ceeMaintenanceManager"],
    rentalManager: ["rentalManager"],
    habManager: ["habManager"],
    consumptionManager: ["consumptionManager"],
    contentPublisher: ["contentPublisher"]
  },
  defaultLang: "all",
  defaultLayout: "default",
  debugMode: process.env.VUE_APP_DEBUG_MODE
    ? process.env.VUE_APP_DEBUG_MODE
    : false,
  /*session: {
    keepSessionAlive: process.env.VUE_APP_KEEP_SESSION_ALIVE
      ? process.env.VUE_APP_KEEP_SESSION_ALIVE
      : false,
    sessionPingIntervalInSeconds: 60
  },*/
  showTestEnvironmentNag:
    process.env.VUE_APP_TEST_ENVIRONMENT_NAG === true ? true : false,
  applicationFeatures: ApplicationFeatures,
  attachments: {
    folders: {
      partnerCompany: "partner_company",
      serviceRequests: "service_request_attachments_all",
      temp: "temp"
    },
    maxSizeInBytes: 36700160,
    allowedMimeTypes: [
      "image/",
      "application/pdf",
      "application/acad",
      "application/x-acad",
      "application/autocad_dwg",
      "image/x-dwg",
      "application/dwg",
      "application/x-dwg",
      "application/x-autocad",
      "image/vnd.dwg",
      "drawing/dwg",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      "application/vnd.",
      "etsi.asic-e+zip"
    ],
    allowedFileExtensions: [
      "asice",
      "jpg",
      "jpeg",
      "png",
      "ico",
      "gif",
      "webp",
      "tiff",
      "dwg",
      "pdf",
      "xls",
      "xlsx",
      "doc",
      "ppt",
      "pptx",
      "pps",
      "ppsx",
      "odt",
      "key",
      "docx"
    ]
  },
  imageFiles: {
    maxSizeInBytes: 35000000,
    allowedMimeTypes: [
      "image/jpg",
      "image/gif",
      "image/jpeg",
      "image/png"
    ]
  },
  community: {
    attachmentsTempFolder: "community_all",
    itemsPerPage: 25
  },
  documents: {
    folders: {
      apartmentFolderNames: [
        'apt_owner',
        'apt_documents',
        'apt_invoices',
        'apt_maintenance',
        'apt_tenant',
      ]
    }
  },
  userManagement : {
    apartmentRoles: ["owner", "tenant", "familyMember"],
    ceeApartmentRoles: ["owner", "tenant", "familyMember", "rentalManager"],
    hcRoles: ["boardMember", "chairman"],
    ceeHcRoles: ["ceeBoardMember", "chairman", "habManager"],
    propertyManagerRoles: ['propertyManager'],
    maintenanceManagerRoles: ['maintenanceManager'],
    ceeMaintenanceManagerRoles: ['ceeMaintenanceManager'],
    businessPartnerRoles: ['partner']
  }
};

//Add "all languages" for admin purposes
settings.editorLangs = _.clone(settings.langs);
settings.editorLangs.unshift({
  langKey: "all",
  locale: "all",
  localeDisplayName: i18n.global.t("common.language.all")
});

//Guess user locale
let locale = 'en';
const availableLanguages = settings.langs.map(function(el) {
  return el.langKey;
});
const langParam = getURLParam(location.href, "lang");  
if(langParam && availableLanguages.includes(langParam)) {
  settings.locale = langParam;
} else {
  let localeFromBrowser = getBrowserLocale() || 'en';
  if(availableLanguages && availableLanguages.includes(localeFromBrowser))
    locale = localeFromBrowser;
  
  settings.locale = locale;
}

export default settings;